import { Component, OnInit, Input, TemplateRef} from '@angular/core';
import { Router} from '@angular/router';
import { Product } from 'src/app/models/product';
import { Others } from 'src/app/models/others';
import { Message } from 'src/app/models/message';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgForm, Form } from '@angular/forms';
import { Observable } from 'rxjs';
import { CatalogoService } from './../../services/catalogo.service';
import { MessagesService } from './../../services/messages.service';
import { AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from './../../services/auth.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.css']
})
export class TarjetaComponent implements OnInit {

  @Input() ArrayIndex: number;
  @Input() Product: Product;
  @Input() WhatAmI: boolean
  estado: boolean;
  modalRef: BsModalRef; //Abrir detalles tarjeta
  modalRef2: BsModalRef; //Enviar mensaje a admin
  modalRef3: BsModalRef; //Editar detalles tarjeta
  modalRef4: BsModalRef; //Borrar tarjeta del catalogo
  modalRef5: BsModalRef; //Abrir detalles tarjeta admin
  selectedProduct;
  nameValue;
  descriptionValue;
  priceValue;
  uploadProgress: Observable<number>; 
  ref: AngularFireStorageReference;
  downloadURL: Observable<string>;
  imageUrl: string = null;
  oldimageUrl = null;
  message: Message = new Message();

  constructor(private modalService: BsModalService, public auth: AuthService, private router: Router, 
    private catalogoService: CatalogoService, private messageService: MessagesService, private storage: AngularFireStorage) 
  {}

  ngOnInit() {}

  OpenDetails(template: TemplateRef<any>) 
  {
    this.router.navigate(['dashboard/detalle',this.WhatAmI,this.ArrayIndex]);
  }
  OpenDetailsOthers(template: TemplateRef<any>) 
  {
    this.router.navigate(['/detalle',false,this.ArrayIndex]);
    // this.modalRef = this.modalService.show(template);
  }

  OpenEdit(template: TemplateRef<any>, editProduct)
  {
    this.selectedProduct = editProduct;
    this.nameValue=this.selectedProduct.name;
    this.descriptionValue = this.selectedProduct.descrition;
    this.priceValue = this.selectedProduct.price;
    this.modalRef3 = this.modalService.show(template, { class: 'modal-lg' });
    this.modalRef3.hide();
  }

  OpenConfirmation(template: TemplateRef<any>)
  {
    this.modalRef4 = this.modalService.show(template, { class: 'second' });
    this.modalRef4.hide();
  }

  OpenDetailsAdmin(template: TemplateRef<any>) 
  {
    this.modalRef5 = this.modalService.show(template);
    this.modalRef5.hide();
  }

  removeAvaliable(product)
  {
    var estado = this.catalogoService.estadoActual();
    if(estado)
    {
      var id = product.id;
      this.catalogoService.quitarDisponibilidad(id, product);
    }
    else
    {
      var id = product.id;
      alert(id)
      alert(product)
      this.catalogoService.quitarDisponibilidadO(id, product);
    }
  }

  addAvaliable(product)
  {
    var estado = this.catalogoService.estadoActual();
    if(estado)
    {
      var id = product.id;
      this.catalogoService.agregarDisponibilidad(id, product);
    }
    else
    {
      alert("llegue");
      var id = product.id;
      this.catalogoService.agregarDisponibilidadO(id, product);
    }
  }


  upload(event) 
  {
    // Obtiene la imagen:
    const file = event.target.files[0];
    
    // Genera un ID random para la imagen:
    const randomId = Math.random().toString(36).substring(2);
    const filepath = `Imágenes/products/${randomId}`;
    // Cargar imagen:
    const task = this.storage.upload(filepath, file);
    this.ref = this.storage.ref(filepath);
    // Observa los cambios en el % de la barra de progresos:
    this.uploadProgress = task.percentageChanges();
    // Notifica cuando la URL de descarga está disponible:
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL();  
        this.downloadURL.subscribe(url => {this.imageUrl = url} );
      })
    ).subscribe();
  }
  
  deleteImage(urlToDelete: string)
  {
    if(urlToDelete!=null)
    {
      this.storage.refFromURL(urlToDelete).delete().toPromise().then( () => {
      // Successfully deleted
      }).catch( err => {
          //Handle err
      });
    }
  }

    updateProduct(form: NgForm)
  {
    var id = this.selectedProduct.id;  

    if(form.value.name != ""){
      this.selectedProduct.name = form.value.name;
      this.selectedProduct.lowerCase = this.selectedProduct.name.toLowerCase();
    }
    if(form.value.descripcion != ""){
      this.selectedProduct.descrition = form.value.descripcion;
    }
    if(form.value.precio != undefined){
      this.selectedProduct.price = +form.value.precio;
    }
    if(this.imageUrl!=null)
    {
      this.oldimageUrl=this.selectedProduct.photoUrl.toString();
      this.selectedProduct.photoUrl=this.imageUrl;
      this.deleteImage(this.oldimageUrl);
      this.imageUrl=null;
    }
    var estado = this.catalogoService.estadoActual();
    if(estado)
    {
       this.catalogoService.updateProducto(id, this.selectedProduct);
    }
    else
    {
       this.catalogoService.updateProductoO(id, this.selectedProduct);
    }
    this.modalRef3.hide();
    this.modalRef3 = null;
  }

  decline(): void 
  {

    this.modalRef3.hide();
    if(this.imageUrl!=null)
    {
      this.deleteImage(this.imageUrl);
      this.imageUrl=null;
    }
    this.imageUrl=null;
  }

  //Borrar el producto
  delete(product)
  {
    var id = product.id;
    var estado = this.catalogoService.estadoActual();
    if(estado)
    {
      this.catalogoService.deleteProducto(id);
    }
    else
    {
      this.catalogoService.deleteProductoO(id);
    }
    this.modalRef4.hide();
    this.deleteImage(product.photoUrl);
  }
}
