import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgModule } from '@angular/core';
import {environment} from '../environments/environment';

// Rutas:
import { APP_ROUTING } from './app.routes';

// Servicios:
import { CatalogoService } from './services/catalogo.service';
import { AdminService } from './services/admin.service';
import { AuthService } from './services/auth.service';

// Componentes:
import { AppComponent } from './app.component';
import { AdminComponent } from './components/admin/admin.component';
import { SearchComponent } from './components/search/search.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ShopComponent } from './components/shop/shop.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TarjetaComponent } from './components/tarjeta/tarjeta.component';
import { ItemComponent } from './components/item/item.component';

// FireStore:
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';

//Firebase:
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthGuard } from './auth.guard';

// Ngx-boostrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ProgressbarModule } from 'ngx-bootstrap';
import { MessageComponent } from './components/message/message.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap';

// Firestore Config
export const config = environment.config;

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    SearchComponent,
    DashboardComponent,
    HomeComponent,
    AboutComponent,
    ShopComponent,
    NavbarComponent,
    TarjetaComponent,
    MessageComponent,
    ItemComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    APP_ROUTING,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(config),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot()
  ],
  providers: [CatalogoService, AdminService, AuthService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
