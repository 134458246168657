import { Component, OnInit, TemplateRef } from '@angular/core';
import { CatalogoService } from './../../services/catalogo.service';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/product';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

products;
estadoCreacion: boolean;
isBicicleta: boolean = true;
isOthers: boolean = false;
modalRef6: BsModalRef; //Crear nueva tarjeta
selectedProduct: Product;
nameValue;
descriptionValue;
priceValue;
newProduct: Product = new Product();
//searchText: string = "";
uploadProgress: Observable<number>; 
ref: AngularFireStorageReference;
downloadURL: Observable<string>;
imageUrl: string = null;
oldimageUrl: string = null;

  constructor(private catalogoService: CatalogoService, private modalService: BsModalService, private router: Router,
    private storage: AngularFireStorage, public afs: AngularFirestore) { }

  ngOnInit() 
  {
  		this.catalogoService.getProductos().subscribe(productos => this.products = productos);
  }

  	OpenCreate(template: TemplateRef<any>)
  {
  	this.newProduct.name = "";
    this.newProduct.descrition = "";
    this.newProduct.price = null;
    this.modalRef6 = this.modalService.show(template, { class: 'modal-lg' });
    this.modalRef6.hide();
  }
   createProduct(form: NgForm, estado: boolean)
   {

      this.estadoCreacion=estado;
      console.log(this.estadoCreacion);

	    if(form.value.name === "")
	    {
	      alert("Debe insertar un nombre");
	      return;
	    }
	    else if(form.value.descripcion === "")
	    {
	      alert("Debe insertar una descripción");
	      return;
	    }
	    else if(form.value.precio === null || form.value.precio === "") 
	    {
	      alert("Debe insertar un precio");
	      return;
      }
	    else 
	    {
	      var productoNuevo = 
	      {
	        name: form.value.name,
	        price: form.value.precio,
	        descrition: form.value.descripcion,
	        //Controlar PhotoUrl
	        photoUrl: "",
          disponibility: true,
          quantity: 0,
          lowerCase: form.value.name.toLowerCase()
        }
	    }
	    if(this.imageUrl!=null)
	    {

	      var productoNuevo = {
	        name: form.value.name,
	        price: form.value.precio,
	        descrition: form.value.descripcion,
	        photoUrl: this.imageUrl,
          disponibility: true,
          quantity: 0,
          lowerCase: form.value.name.toLowerCase()
      }
    }
    if(this.estadoCreacion)
    {
      console.log("Bicicleta");
      this.catalogoService.crearProducto(productoNuevo);
    }
    else
    {
      console.log("Repuesto");;
      this.catalogoService.crearProductoO(productoNuevo);
    }
      this.imageUrl=null;
	    this.modalRef6.hide();
	    this.modalRef6 =null;
	}


	confirm(): void 
	{
    	this.modalRef6.hide();
  }

  declineCreate()
  {
    this.modalRef6.hide();
    if(this.imageUrl!=null)
    {
      this.deleteImage(this.imageUrl);
      this.imageUrl=null;
    }
    this.imageUrl=null;
  }


  upload(event) 
  {
    // Obtiene la imagen:
    const file = event.target.files[0];
    
    // Genera un ID random para la imagen:
    const randomId = Math.random().toString(36).substring(2);
    const filepath = `Imágenes/products/${randomId}`;
    // Cargar imagen:
    const task = this.storage.upload(filepath, file);
    this.ref = this.storage.ref(filepath);
    // Observa los cambios en el % de la barra de progresos:
    this.uploadProgress = task.percentageChanges();
    // Notifica cuando la URL de descarga está disponible:
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL();  
        this.downloadURL.subscribe(url => {this.imageUrl = url} );
      })
    ).subscribe();
  }

  deleteImage(urlToDelete: string)
  {
    this.storage.refFromURL(urlToDelete).delete().toPromise().then( () => {
    // Successfully deleted
    }).catch( err => {
         // Handle err
    });
  }

  deleteThumb(urlToDelete: string)
  {
    this.storage.refFromURL(urlToDelete).delete().toPromise().then( () => {
    // Successfully deleted
    }).catch( err => {
         // Handle err
    });
  }

    getProducts(){
    this.isBicicleta = true;
    this.isOthers = false;
    this.catalogoService.getProductos().subscribe(productos => {this.products = productos});
    this.catalogoService.Setestado(this.isBicicleta);
  }
  
  getAccesorios(){
    this.isOthers = true;
    this.isBicicleta = false;
    this.catalogoService.getOthers().subscribe(others => {this.products = others});
    this.catalogoService.Setestado(this.isBicicleta);
  }


}
