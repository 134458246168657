import { Component, OnInit } from '@angular/core';
import { CatalogoService } from './../../services/catalogo.service';
import { Router } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Message } from '../../models/message';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {
  products;
  mensaje: boolean = false;
  isBicicleta: boolean = true;
  isOthers: boolean = false;
  message: Message = new Message();
  searchterm: string = "";
  startAt = new Subject();
  endAt = new Subject();
  startObs = this.startAt.asObservable();
  endObs = this.endAt.asObservable();
  constructor(private catalogoService: CatalogoService, private afs: AngularFirestore, private messageService: MessagesService) 
  {

  }

  ngOnInit() 
  {
    if(this.isBicicleta){
      this.catalogoService.getAvailableProducts().subscribe(productos => {this.products = productos});
      combineLatest(this.startObs, this.endObs).subscribe((value) => {
        this.firequery(value[0], value[1]).subscribe((productos) => {
          this.products = productos;
        })
      }) 
    }
    if(this.isOthers){
      this.catalogoService.getOthers().subscribe(others => {this.products = others});
      combineLatest(this.startObs, this.endObs).subscribe((value) => {
        this.firequery(value[0], value[1]).subscribe((others) => {
          this.products = others;
        })
      }) 
    }
  }
  getProducts(){
    this.isBicicleta = true;
    this.isOthers = false;
    this.catalogoService.getAvailableProducts().subscribe(productos => {this.products = productos});
    this.mensaje =false;
  }
  getAccesorios(){
    this.isOthers = true;
    this.isBicicleta = false;
    this.catalogoService.getOthers().subscribe(others => {this.products = others});
    this.mensaje = false;
  }
  search(event){
    const q = event.toLowerCase();
    if(q!==null){
      this.startAt.next(q);
      this.endAt.next(q + '\uf8ff');
    }else{
      if(this.isBicicleta){
        this.getProducts();
      }
      if(this.isOthers){
        this.getAccesorios();
      }
    }
  }
  firequery(start,end){
    if(this.isBicicleta == true){
      return this.afs.collection('products', ref => ref.orderBy('lowerCase').where("disponibility", "==", true).startAt(start).endAt(end)).valueChanges();  
    }
    if(this.isOthers == true){
      return this.afs.collection('others', ref => ref.orderBy('lowerCase').where("disponibility", "==", true).startAt(start).endAt(end)).valueChanges();  
    }
  }
  sendMessage(){
    var message = {
      name: this.message.name,
      email: this.message.email,
      number: this.message.number,
      text: this.message.text,
      readed: this.message.readed,
    }

    this.messageService.sendMessage(message);
    this.Mensaje();
  }

  Mensaje(){
    this.products = null;
    this.mensaje = true;
    this.message.email = "example@email.com";
    this.message.name = " example ";
    this.message.number = 1112223333;
    this.message.readed = false;
    this.message.text = "example";
  }
}
