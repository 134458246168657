import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './auth.guard';
// Componentes:
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ShopComponent } from './components/shop/shop.component';
import { HomeComponent } from './components/home/home.component';
import { AdminComponent } from './components/admin/admin.component';
import { AboutComponent } from './components/about/about.component';
import { MessageComponent } from './components/message/message.component';
import { ItemComponent } from './components/item/item.component';

const ROUTES: Routes = [
    { path: 'dashboard', component: DashboardComponent,
      children: [
        {path: 'shop', component: ShopComponent},
        {path: 'home', component: HomeComponent},
        {path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},
        {path: 'about', component: AboutComponent},
        {path: 'message', component: MessageComponent, canActivate: [AuthGuard]},
        {path: 'detalle/:bicicleta/:id', component: ItemComponent},
      ]
  },
  { path: '**', pathMatch: 'full', redirectTo: 'dashboard/home' }
];

export const APP_ROUTING = RouterModule.forRoot(ROUTES);
