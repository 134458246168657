import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgForm } from '@angular/forms';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MessagesService } from './../../services/messages.service'; 

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  modalRef: BsModalRef;
  isCollapsed = true;
  constructor(private modalService: BsModalService, public auth: AuthService, private router: Router, private MessageService: MessagesService) {}

  unreadMessage: number = 0;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.hide();
  }

  ngOnInit() {
    this.MessageService.getNumberUnreadedMessages().subscribe(unreadedMessage => {
      this.unreadMessage = unreadedMessage;
    });
  }

  login(form: NgForm)
  {
    const email = form.value.email;
    const password = form.value.password;

    //Ruta de administrador
    this.auth.emailAndPassword(email,password).then(()=> {
      this.modalRef.hide();
      this.router.navigate(['dashboard/admin'])
    }).catch(err => {
      alert(err.message);
    })

  }

}
