import { Component, OnInit } from '@angular/core';
import{ MessagesService } from '../../services/messages.service';
import { Message } from 'src/app/models/message';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  messages;
  oneAtATime: boolean = true;
  title = "Todos los mensajes";
  constructor(private messageService: MessagesService) { }

  ngOnInit() {
    this.messageService.getAllMessages().subscribe(messages => this.messages = messages);
  }

  //Metodo para eliminar mensajes
  delete(message){
    var id = message.id;
    //alert("CREAR UN MODAL DE CONFIRMACION PORQ ESTO SE ELIMINA ES DE ONE");
    this.messageService.deleteMessage(id);
  }

  //Metodo para actualizar el mensaje al ser leido
  readMessage(message){
    var id = message.id;
    this.messageService.readMessage(id, message);
  }
  //Metodo para recibir todos los mensajes
  allMessages(){
    this.title = "Todos los mensajes";
    this.messageService.getAllMessages().subscribe(messages => this.messages = messages);
  }
  //Metodo para recibir solo los mensajes leidos
  readedMessages(){
    this.title = "Mensajes leídos";
    this.messageService.getReadedMessages().subscribe(messages => this.messages = messages);
  }
  //Metodo para recibir solo los mensajes no leidos
  UnreadMessages(){
    this.title = "Mensajes no leídos";
    this.messageService.getUnreadedMessages().subscribe(messages => this.messages = messages);
  }
}
