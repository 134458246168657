import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import {ActivatedRoute} from '@angular/router';
import { CatalogoService } from 'src/app/services/catalogo.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  product: any
  producto: any
  index: number;
  bicicleta: string;
  constructor(private activatedRoute: ActivatedRoute, private catalogoService: CatalogoService) { 
    this.activatedRoute.params.subscribe(params => (this.bicicleta = params['bicicleta']))
    this.activatedRoute.params.subscribe(params => {this.index = params['id']})


}

DetalleDelProducto(){
  console.log(this.product);
}
  ngOnInit() {
    this.getProducto()
  }

  getProducto(){
    if(this.bicicleta == 'true'){
       this.catalogoService.getProduct(this.index).subscribe(item => this.product = item);
    } else{
      this.catalogoService.getOther(this.index).subscribe(item => this.product = item);
    }
  }

}
